<template>
  <div>
    <CCard sm="12">
      <CCardHeader>
        <h1>Create new AWS Account(s)</h1>
        <CButton v-if="formValid" color="info" @click.prevent="submit"
          >Create
        </CButton>
      </CCardHeader>
      <CCardBody sm="12">
        <CRow>
          <CCol sm="5">
            <CRow>
              <CCol sm="12">
                <CInput
                  invalidFeedback="Can not be empty"
                  label="Account Name"
                  placeholder="Enter name"
                  v-model="AccountName"
                  :is-valid="nameValidator"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <template v-for="name in radioNames">
                  <CRow :key="name">
                    <CCol sm="5">
                      {{ name }}
                    </CCol>
                    <CCol sm="1" />
                    <CCol sm="6">
                      <CInputRadioGroup
                        :options="options"
                        :checked.sync="Env"
                      />
                    </CCol>
                  </CRow>
                </template>
              </CCol>
            </CRow>
            <CRow v-if="Env === '1'">
              <CCol sm="12">
                <span>
                  {{
                    "Accounts created: " +
                    AccountName +
                    "-prod and " +
                    AccountName +
                    "-noprod"
                  }}
                </span>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                  label="Manager"
                  placeholder="Enter manager GID"
                  invalidFeedback="Must be a valid GID"
                  :is-valid="managerValidator"
                  v-model="Manager"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                  label="Alternate Managers (optional)"
                  placeholder="Enter alternate managers GID separated by a ,"
                  invalidFeedback="Must be a valid GID's"
                  v-model="AlternateManagers"
                  :is-valid="alternateManagersValidator"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                  label="DL Operation  (optional)"
                  placeholder="Enter DlOperation if empty a new one is created"
                  v-model="DlOperation"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                  label="DL Security  (optional)"
                  placeholder="Enter DlSecurity if empty a new one is created"
                  v-model="DlSecurity"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                  label="DL Billing  (optional)"
                  placeholder="Enter DlBilling if empty a new one is created"
                  v-model="DlBilling"
                />
              </CCol>
            </CRow>
            <br />
          </CCol>
          <CCol sm="3">
            <span>Regions (if nothing selected, eu-west-1)</span>
            <select v-model="Regions" multiple class="regions">
              <option
                :key="region.value"
                :value="region.value"
                v-for="region in AvailableRegions"
              >
                {{ region.label }}
              </option>
            </select>
          </CCol>
          <CCol sm="4">
            <CRow>
              <CCol sm="12">
                <CInput
                  disabled
                  label="BU"
                  placeholder="Select in the table"
                  v-model="BU"
                  invalidFeedback="Row must be selected in the table"
                  :is-valid="orgIDValidator"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                  disabled
                  label="ORGID"
                  placeholder="Select in the table"
                  v-model="OrgId"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                  disabled
                  label="Entity"
                  placeholder="Select in the table"
                  v-model="BE"
                />
              </CCol>
            </CRow>
            <CDataTable
              :items="orgIds"
              :loading="Loading"
              :items-per-page="5"
              clickableRows
              @row-clicked="rowClicked"
              column-filter
              hover
              outlined
              pagination
              responsive
              size="sm"
              sorter
              striped
            >
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      Loading: false,
      BU: "",
      BE: "",
      OrgId: "",
      Manager: "",
      AlternateManagers: "",
      AccountName: "",
      Regions: [],
      DlOperation: "",
      DlBilling: "",
      DlSecurity: "",
      Env: "2",
      orgIds: [],
      formValid: false,
      validation: {
        name: false,
        manager: false,
        alternateManagers: true,
        DlOperation: true,
        DlBilling: true,
        DlSecurity: true,
        orgID: false
      },
      radioNames: ["Environnement:"],
      options: [
        { value: "1", label: "Prod and NoProd" },
        { value: "2", label: "Only Account Name" }
      ],
      AvailableRegions: [{ value: "eu-west-1", label: "Europe (Ireland)" }]
    };
  },
  created() {
    this.loadEntities();
    this.loadRegions();
  },
  methods: {
    async submit() {
      let payload = {};
      if (this.Env == 2) {
        payload = {
          Bu: this.BU,
          Be: this.BE,
          OrgId: this.OrgId,
          AccountOwner: this.Manager,
          AdditionalAccountOwners: this.AlternateManagers.split(","),
          AccountName: this.AccountName,
          Regions: this.Regions,
          DlOperation: this.DlOperation,
          DlSecurity: this.DlSecurity,
          DlBilling: this.DlBilling
        };
        console.log(payload);
        await this.createAccount(payload);
      } else {
        payload = {
          Bu: this.BU,
          Be: this.BE,
          OrgId: this.OrgId,
          AccountOwner: this.Manager,
          AdditionalAccountOwners: this.AlternateManagers.split(","),
          AccountName: this.AccountName + "-prod",
          Regions: this.Regions,
          DlOperation: this.DlOperation,
          DlSecurity: this.DlSecurity,
          DlBilling: this.DlBilling
        };
        console.log(payload);
        await this.createAccount(payload);
        payload = {
          Bu: this.BU,
          Be: this.BE,
          OrgId: this.OrgId,
          AccountOwner: this.Manager,
          AdditionalAccountOwners: this.AlternateManagers.split(","),
          AccountName: this.AccountName + "-noprod",
          Regions: this.Regions,
          DlOperation: this.DlOperation,
          DlSecurity: this.DlSecurity,
          DlBilling: this.DlBilling
        };
        console.log(payload);
        await this.createAccount(payload);
      }
    },
    isFormValid() {
      console.log(this.formValid);
      this.formValid =
        this.validation.name &&
        this.validation.manager &&
        this.validation.orgID;
    },
    orgIDValidator(val) {
      if (val == undefined) this.validation.orgID = false;
      else {
        const regex = /^([A-Z]|[a-z]|[0-9])+$/g;
        const found = val.match(regex);
        this.validation.orgID = !(found == null);
      }
      this.isFormValid();
      return this.validation.orgID;
    },
    nameValidator(val) {
      if (val == undefined) this.validation.name = false;
      else {
        const regex = /^([-A-Z]|[-a-z]|[0-9])+$/g;
        const found = val.match(regex);
        this.validation.name = !(found == null);
      }
      this.isFormValid();
      return this.validation.name;
    },
    managerValidator(val) {
      if (val == undefined) this.validation.manager = false;
      else {
        // const regex = /^([A-Z]|[a-z]|[0-9]){6}-([A-Z]|[a-z])$/g;
        const regex = /^([A-Z]|[a-z]|[0-9]){6}$/g;
        const found = val.match(regex);
        this.validation.manager = !(found == null);
      }
      // this.isFormValid();
      this.isFormValid();
      return this.validation.manager;
    },
    alternateManagersValidator(val) {
      let isValid = true;
      if (val === "") return true;
      if (val === undefined) isValid = true;
      else {
        var nameArr = val.split(",");
        for (let k of nameArr) {
          const regex = /^([A-Z]|[a-z]|[0-9]){6}$/g;
          const found = k.match(regex);
          this.validation.alternateManagers = !(found == null);
          isValid = isValid && !(found == null);
        }
      }
      this.isFormValid();
      return isValid;
    },
    rowClicked(e) {
      this.BU = e.BU;
      this.OrgId = e.OrgId;
      this.BE = e.BE;
    },
    async loadEntities() {
      console.log("test");
      try {
        this.Loading = true;
        var accessToken = await this.$auth.getAccessToken();
        await this.$store.dispatch("createAccountModule/loadEntities", {
          accessToken: accessToken
        });
        this.Loading = false;
        this.orgIds = this.$store.getters["createAccountModule/getOrgID"];
      } catch (e) {
        console.log(e);
      }
    },
    async loadRegions() {
      try {
        this.Loading = true;
        var accessToken = await this.$auth.getAccessToken();
        await this.$store.dispatch(
          "portalParametersModule/loadRegionsParameters",
          {
            accessToken: accessToken
          }
        );
        this.Loading = false;
        this.AvailableRegions = this.$store.getters[
          "portalParametersModule/regions"
        ];
      } catch (e) {
        console.log(e);
      }
    },
    async createAccount(payload) {
      var accessToken = await this.$auth.getAccessToken();
      try {
        const config = {
          headers: {
            authorizationToken: "Bearer " + accessToken,
            "Content-Type": "application/json"
          }
        };
        const URL = process.env.VUE_APP_BACKEND_BASEURL + "/accounts/";
        const response = await axios.post(URL, payload, config);
        return {
          success: true,
          data: response.data
        };
      } catch (error) {
        if (error.response) {
          // status code that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // no response
          console.log(error.request);
        } else {
          // other
          console.log("Error", error.message);
        }
        throw new Error("Unable to create account");
      }
    }
  }
};
</script>
<style  scoped>
.regions {
  height: 500px;
  width: 100%;
}
</style>
